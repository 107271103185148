<template>
  <validation-observer
      ref="studentFormRef"
      #default="{invalid}"
  >
    <div class="row">
      <b-container fluid>
        <b-card>
          <b-overlay
              :show="isLoading"
              rounded="sm"
          >
            <b-form>
              <b-row>
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Chương trình đào tạo/Bồi dưỡng"
                      label-for="trainingSystemId"
                  >
                    <v-select
                        v-model="filter.trainingSystemId"
                        :options="trainingSystems"
                        :reduce="option => option.value"
                        @input="onTrainingSystemsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Khóa học"
                      label-for="courseId"
                  >
                    <v-select
                        v-model="filter.courseId"
                        :options="courses"
                        :reduce="option => option.value"
                        @input="onCoursesChange"
                    />
                  </b-form-group>
                </b-col>
                <!--                <b-col-->
                <!--                  cols="12"-->
                <!--                  md="4"-->
                <!--                >-->
                <!--                  <b-form-group-->
                <!--                    label="Khoa/Bộ môn"-->
                <!--                    label-for="departmentId"-->
                <!--                  >-->
                <!--                    <v-select-->
                <!--                      v-model="filter.departmentId"-->
                <!--                      :options="departments"-->
                <!--                      :reduce="option => option.value"-->
                <!--                      @input="onDepartmentChange"-->
                <!--                    />-->
                <!--                  </b-form-group>-->
                <!--                </b-col>-->
                <!--                <b-col-->
                <!--                  cols="12"-->
                <!--                  md="4"-->
                <!--                >-->
                <!--                  <b-form-group-->
                <!--                    label="Ngành học"-->
                <!--                    label-for="majorId"-->
                <!--                  >-->
                <!--                    <v-select-->
                <!--                      v-model="filter.majorId"-->
                <!--                      :options="majors"-->
                <!--                      :reduce="option => option.value"-->
                <!--                      @input="onMajorChange"-->
                <!--                    />-->
                <!--                  </b-form-group>-->
                <!--                </b-col>-->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Lớp cố định"
                      label-for="classId"
                  >
                    <v-select
                        v-model="classId"
                        :options="classes"
                        :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    class="text-right"
                >
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="my-2 mx-2"
                      title="Lấy danh sách các nhóm của lớp"
                      @click="onGetList"
                  >
                    <feather-icon icon="FilterIcon" /> Danh sách
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="invalid"
                      variant="success"
                      class="my-2"
                      title="Lưu điểm"
                      @click="onSave"
                  >
                    <feather-icon icon="CheckIcon" /> Lưu lại
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="invalid"
                      variant="danger"
                      class="my-2 mx-2"
                      title="Khóa điểm"
                      @click="onLockScore"
                  >
                    <feather-icon icon="LockIcon" /> Chốt điểm
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </b-card>
        <b-card>
          <b-row
              v-if="listStudent.length === 0"
          >
            <b-col>
              <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
              >
                Không có thông tin!
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <app-collapse
                  type="margin"
              >

                <app-collapse-item
                    v-for="(item, index) in listGroups"
                    :key="index"
                    ref="row"
                    :title="`Nhập điểm cho học viên ${item.name}`"
                >
                  <vue-good-table
                      :columns="columns"
                      :rows="listStudent[index]"
                      :pagination-options="paginationOptions"
                      :line-numbers="true"
                      class="my-table"
                      style-class="my-table vgt-table striped bordered"
                  >
                    <div
                        slot="emptystate"
                        style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                        slot="table-row"
                        slot-scope="props"
                    >
                      <span v-if="props.column.field === 'score'">
                        <validation-provider
                            #default="{ errors }"
                            rules="required|between:0,10"
                            name="Điểm"
                        >
                          <b-form-input
                              v-model="props.row.score"
                              :tabindex="props.row.originalIndex + 1"
                              type="number"
                              :readonly="props.row.status === 2"
                              @change="changeCell(
                              props.row.score,
                              props.column.field,
                              props.row.originalIndex,
                              index
                            )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </span>
                      <span v-else-if="props.column.field === 'note'">
                        <b-form-input
                            v-model="props.row.note"
                            type="text"
                            @change="
                            changeCell(
                              props.row.note,
                              props.column.field,
                              props.row.originalIndex,
                              index
                            )
                          "
                        />
                      </span>
                      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    </template>
                  </vue-good-table>
                </app-collapse-item>
              </app-collapse>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </div>
  </validation-observer>
</template>
<script>

import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem, BForm, BFormGroup, BFormInput,
  BFormSelect, BOverlay, BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import { required } from '@core/utils/validations/validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'PracticalResearchScore',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    BFormInput,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isLoading: false,
      paginationOptions: {
        enabled: false,
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 3000,
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        departmentId: '',
        majorId: '',
      },
      classId: null,
      required,
      columns: [
        {
          label: 'Mã học viên',
          field: 'code',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ tên',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lớp học',
          field: 'class',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm bằng số',
          field: 'score',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'note',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      listStudent: [],
      listGroups: [],
      listStudentExamAgain: [],
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'practicalResearchClassStudent/trainingSystems',
      courses: 'practicalResearchClassStudent/courses',
      departments: 'practicalResearchClassStudent/departments',
      majors: 'practicalResearchClassStudent/majors',
      classes: 'practicalResearchClassStudent/classes',
      groupsPracticalResearch: 'practicalResearchClassGroup/groupsPracticalResearch',
      studentsPracticalResearchGroup: 'practicalResearchClassStudent/studentsPracticalResearchGroup',
    }),
  },
  async created() {
    this.isLoading = true
    this.classId = null
    try {
      const cUser = getUser()
      this.filter.organizationId = (cUser.orgId ? cUser.orgId : 0)
      await Promise.all([
        this.getTrainingSystems({ organizationId: this.filter.organizationId }),
        this.getDepartments({ organizationId: this.filter.organizationId }),
      ])
      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
        await this.getCourses(this.filter)
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystems: 'practicalResearchClassStudent/getTrainingSystems',
      getCourses: 'practicalResearchClassStudent/getCourses',
      getDepartments: 'practicalResearchClassStudent/getDepartments',
      getMajors: 'practicalResearchClassStudent/getMajors',
      getClasses: 'practicalResearchClassStudent/getClasses',
      readGroupsPracticalResearch: 'practicalResearchClassGroup/readGroupsPracticalResearch',
      readStudentsPracticalResearchByGroup: 'practicalResearchClassStudent/readStudentsPracticalResearchByGroup',
      updateStudentScorePracticalResearchClass: 'practicalResearchClassStudent/updateStudentScorePracticalResearchClass',
      addStudentPracticalResearchClassGroup: 'practicalResearchClassStudent/addStudentPracticalResearchClassGroup',
    }),
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      this.courseName = ''
      this.courseSemesterName = ''
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.courseName = this.courses.find(course => course.value === event).label
      this.filter.courseSemesterId = null
      this.courseSemesterName = ''
      this.classId = null
      await this.getClasses({
        courseId: this.filter.courseId,
        trainingSystemId: this.filter.trainingSystemId,
      })
    },
    async onDepartmentChange(event) {
      this.filter.departmentId = event
      this.filter.majorId = null
      await this.getMajors(this.filter)
    },
    async onMajorChange(event) {
      this.filter.majorId = event
      this.filter.programmeId = null
      await this.getClasses({
        courseId: this.filter.courseId,
        trainingSystemId: this.filter.trainingSystemId,
        departmentId: this.filter.departmentId,
        majorId: this.filter.majorId,
      })
    },
    async onGetList() {
      this.isLoading = true
      try {
        await this.readGroupsPracticalResearch({ classId: this.classId, courseId: this.filter.courseId, trainingSystemId: this.filter.trainingSystemId })
        this.listGroups = this.groupsPracticalResearch
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      this.listStudent = []
      for (const item of this.listGroups) {
        await this.readStudentsPracticalResearchByGroup({ groupId: item.id })
        this.listStudent.push(this.studentsPracticalResearchGroup)
      }
    },
    changeCell(data, column, row, index) {
      this.$set(this.listStudent[index][row], column, Number(data))
    },
    async onLockScore() {
      this.$swal({
        title: 'Bạn có chắc chắn muốn khóa điểm thi?',
        text: 'Bạn sẽ không thể nhập điểm',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            this.listStudent.forEach((item, index) => {
              item.forEach(student => {
                student.status = 2
              })
            })
            const response = await this.updateStudentScorePracticalResearchClass(this.listStudent)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              }
              await this.onGetList()
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async onSave() {
      this.isLoading = true
      this.listStudentExamAgain = []
      this.listStudent.forEach((item, index) => {
        item.forEach((student, studentIndex) => {
          if ((student.score > 5 && student.status !== 2) || student.status === 0) {
            student.status = 1
          }
          if (student.score < 5 && student.examTime === 1 && student.status === 1) {
            student.status = 3
          }
          if (student.status === 3 && student.examTime === 1) {
            student.examTime = 2
            student.note = 'Điểm bài thi viết lại'
            const {
              id,
              score,
              ...info
            } = student
            this.listStudentExamAgain.push(info)
            student.examTime = 1
            student.status = 2
            student.note = null
          }
        })
      })
      try {
        const response = await this.updateStudentScorePracticalResearchClass(this.listStudent)
        await this.addStudentPracticalResearchClassGroup(this.listStudentExamAgain)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        }
        await this.onGetList()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
